import { createSlice } from "@reduxjs/toolkit";
import {
  getOfficeInfoService,
  updateOfficeService,

} from "../services/office_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingOfficeInfo: false,
  officeInfo: {},
  isLoadingUpdate:false
};

const slice = createSlice({
  name: "office",
  initialState,
  reducers: {},
  extraReducers: {
    // GET =>  GET OFFICE
    [getOfficeInfoService.fulfilled]: (state, action) => {
      state.isLoadingOfficeInfo = false;
      state.officeInfo = action.payload?.data;
    },
    [getOfficeInfoService.rejected]: (state, action) => {
      state.isLoadingOfficeInfo = false;
    },
    [getOfficeInfoService.pending]: (state) => {
      state.isLoadingOfficeInfo = true;
    },

      // GET =>  GET OFFICE
      [updateOfficeService.fulfilled]: (state, action) => {
        state.isLoadingUpdate = false;
     
      },
      [updateOfficeService.rejected]: (state, action) => {
        state.isLoadingUpdate = false;
      },
      [updateOfficeService.pending]: (state) => {
        state.isLoadingUpdate = true;
      },
  },
});

// Reducer
export default slice.reducer;
