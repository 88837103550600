import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET KEY PRACTICE AREA
export const getKeyPracticeAreaService = createAsyncThunk(
  "area/getKeyPracticeAreaService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getKeyPracticeAreaURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// PUT =>  PUT  KEY PRACTICE AREA
export const updateKeyPracticeAreaService = createAsyncThunk(
  "area/updateKeyPracticeAreaService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.updateKeyPracticeAreaURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET TOPICS SERVICE
export const getTopicService = createAsyncThunk(
  "area/getTopicService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.topicURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  POST TOPIC
export const createTopicService = createAsyncThunk(
  "area/createTopicService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createTopicURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  PUT TOPIC
export const updateTopicService = createAsyncThunk(
  "area/updateTopicService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.topicURL}${params.id}/update/`,
        params?.data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET TOPIC BY ID
export const getTopicByIdService = createAsyncThunk(
  "area/getTopicByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.topicURL}${id}/show/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET CONTENT BY ID
export const getContentByIdService = createAsyncThunk(
  "area/getContentByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/practice-areas/contents/${id}/show/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// DELETE =>  DELETE TOPIC
export const deleteTopicService = createAsyncThunk(
  "area/deleteTopicService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.topicURL}${id}/delete/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET CONTENTS SERVICE
export const getContentsService = createAsyncThunk(
  "area/getContentsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `/practice-areas/topic/${params?.id}/contents/`,
        {
          params: { ...params },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  CREATE COUNT CONTENTS SERVICE
export const createCountContentService = createAsyncThunk(
  "area/createCountContentService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `/practice-areas/contents/create/`,
        data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// DELETE =>  DELETE CONTENT
export const deleteContentService = createAsyncThunk(
  "area/deleteContentService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(
        `/practice-areas/contents/${id}/delete/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// UPDATE =>  UPDATE COUNT CONTENTS SERVICE
export const updateContentService = createAsyncThunk(
  "area/updateContentService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `/practice-areas/contents/${params?.id}/update/`,
        params?.data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
