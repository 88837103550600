import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET JOBS
export const getJobsService = createAsyncThunk(
  "jobs/getJobsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.jobURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// CREATE =>  CREATE JOB
export const createJobService = createAsyncThunk(
  "jobs/createJobService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createJobURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET JOB DETAILES
export const getJobDetailesService = createAsyncThunk(
  "jobs/getJobDetailesService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.put(`${URLS.jobURL}/${id}/show/`);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
