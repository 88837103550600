import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET PEOPLE TYPE
export const getPeopleTypeService = createAsyncThunk(
  "people/getPeopleTypeService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.peopleTypesURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  POST PEOPLE
export const createPeopleTypeService = createAsyncThunk(
  "people/createPeopleTypeService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createPeopleTypesURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// PUT =>  PUT PEOPLE
export const updatePeopleTypeService = createAsyncThunk(
  "people/updatePeopleTypeService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(`${URLS.peopleTypesURL}${params.id}/update/`, params?.data, {
        params: {
          ...params?.data,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//GET => GET PPEOPLE TYPE BY ID SERVIDE

export const getPeopleTypeByIDService = createAsyncThunk(
  "people/getPeopleTypeByIDService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.peopleTypesURL}${id}/show/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//GET => GET PPEOPLE  BY ID SERVIDE

export const getPeopleByIDService = createAsyncThunk(
  "people/getPeopleByIDService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.peopleURL}${id}/show/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// DELETE =>  DELETE PEOPLE
export const deletePeopleTypeService = createAsyncThunk(
  "people/deletePeopleTypeService",
  async (id,thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.peopleTypesURL}${id}/delete/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


////////////////////////////////////////////

// GET =>  GET PEOPLE 
export const getPeopleService = createAsyncThunk(
    "people/getPeopleService",
    async (_, thunkAPI) => {
      try {
        const response = await axios.get(URLS.peopleURL);
        return response;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  
  // POST =>  POST PEOPLE
  export const createPeopleService = createAsyncThunk(
    "people/createPeopleService",
    async (data, thunkAPI) => {
      try {
        const response = await axios.post(URLS.createPeopleURL, data, {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        });
        return response;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  
  // PUT =>  PUT PEOPLE
  export const updatePeopleService = createAsyncThunk(
    "people/updatePeopleService",
    async (params, thunkAPI) => {
      try {
        const response = await axios.put(`${URLS.peopleURL}${params.id}/update/`, params?.data, {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        });
        return response;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  
  // DELETE =>  DELETE PEOPLE
  export const deletePeopleService = createAsyncThunk(
    "people/deletePeopleService",
    async (id,thunkAPI) => {
      try {
        const response = await axios.delete(`${URLS.peopleURL}${id}/delete/`);
        return response;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  

