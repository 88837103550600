import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET CLIENTS
export const getClientsService = createAsyncThunk(
  "clients/getClientsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.clientsURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  POST CLIENT
export const createClientService = createAsyncThunk(
  "clients/createClientService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createClientURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// PUT =>  put Client
export const updateClientService = createAsyncThunk(
  "clients/updateClientService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(`${URLS.clientsURL}${params.id}/update/`, params?.data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET Client BY ID
export const getClientByIdService = createAsyncThunk(
  "clients/getClientByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.clientsURL}${id}/show/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// DELETE =>  DELETE CLIENT
export const deleteClientService = createAsyncThunk(
  "clients/deleteBlogService",
  async (id,thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.clientsURL}${id}/delete/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
