import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET OFFICE
export const getOfficeInfoService = createAsyncThunk(
  "office/getOfficeInfoService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.officeInfoURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// UPDATE =>  UPDATE OFFICE 
export const updateOfficeService = createAsyncThunk(
  "office/updateOfficeService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.updateOfficeInfoURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
