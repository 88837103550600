import { createSlice } from "@reduxjs/toolkit";
import {
  getPeopleTypeService,
  createPeopleTypeService,
  updatePeopleTypeService,
  deletePeopleTypeService,
  getPeopleTypeByIDService,
  //
  getPeopleService,
  createPeopleService,
  updatePeopleService,
  deletePeopleService,
  getPeopleByIDService,
  
} from "../services/people_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingPeople: false,
  people_types: [],
  people: [],
  totalElements: null,
  isLoadingDelete: false,
  isLoadingUpdate: false,
  isLoadingCreate: false,
  metaPeopleType: [],
  peopleTypeId: null,
  peopleTypeInfo: {},
  peopleInfo:{}
};

const slice = createSlice({
  name: "people",
  initialState,
  reducers: {
    setPeopleTypeId: (state, action) => {
      state.peopleTypeId = action.payload;
    },
    setPeopleTypeInfo: (state, action) => {
      state.peopleTypeInfo = action.payload;
    },
  },
  extraReducers: {
    // ==> GET PEOPLE TYPE
    [getPeopleTypeService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.people_types = action.payload?.data;
      state.metaPeopleType = action.payload;
    },
    [getPeopleTypeService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getPeopleTypeService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> CREATE PEOPLE TYPE
    [createPeopleTypeService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPeopleTypeService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPeopleTypeService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> UPDATE PEOPLE
    [updatePeopleTypeService.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updatePeopleTypeService.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updatePeopleTypeService.pending]: (state) => {
      state.isLoadingUpdate = true;
    },

      // ==> UPDATE PEOPLE
      [getPeopleTypeByIDService.fulfilled]: (state, action) => {
        state.isLoadingUpdate = false;
        state.peopleTypeInfo=action.payload.data
      },
      [getPeopleTypeByIDService.rejected]: (state, action) => {
        state.isLoadingUpdate = false;
      },
      [getPeopleTypeByIDService.pending]: (state) => {
        state.isLoadingUpdate = true;
      },


        // ==> UPDATE PEOPLE
        [getPeopleByIDService.fulfilled]: (state, action) => {
          state.isLoadingUpdate = false;
          state.peopleInfo=action.payload.data
        },
        [getPeopleByIDService.rejected]: (state, action) => {
          state.isLoadingUpdate = false;
        },
        [getPeopleByIDService.pending]: (state) => {
          state.isLoadingUpdate = true;
        },
    // ==> DELETE PEOPLE TYPE
    [deletePeopleTypeService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deletePeopleTypeService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deletePeopleTypeService.pending]: (state) => {
      state.isLoadingDelete = true;
    },

    // ==> GET PEOPLE
    [getPeopleService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.people = action.payload?.data;
      state.totalElements = action.payload?.totalElements;
    },
    [getPeopleService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getPeopleService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> CREATE PEOPLE
    [createPeopleService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPeopleService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPeopleService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> UPDATE PEOPLE
    [updatePeopleService.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updatePeopleService.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updatePeopleService.pending]: (state) => {
      state.isLoadingUpdate = true;
    },

    // ==> DELETE PEOPLE
    [deletePeopleService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deletePeopleService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deletePeopleService.pending]: (state) => {
      state.isLoadingDelete = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setPeopleTypeId, setPeopleTypeInfo } = slice.actions;
