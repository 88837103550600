import { createSlice } from "@reduxjs/toolkit";

import {
  getContactService,
  createContactService,
  getContactDetailesService,
} from "../services/contact_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingCodes: false,
  contacts: [],
  numberOfElements: null,
  isLoadingCreate: false,
  isLoadingEdit: false,
  isLoadingDelete: false,
  metaContact:null
};

const slice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers: {
    // ==> GET CONTACT
    [getContactService.fulfilled]: (state, action) => {
      state.isLoadingCodes = false;
      state.contacts = action.payload.data;
      state.metaContact = action.payload;
    },
    [getContactService.rejected]: (state, action) => {
      state.isLoadingCodes = false;
    },
    [getContactService.pending]: (state) => {
      state.isLoadingCodes = true;
    },

    // POST =>  POST CONTACT

    [createContactService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createContactService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createContactService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // GET =>  GET CONTACT BY ID
    [getContactDetailesService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [getContactDetailesService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [getContactDetailesService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
  },
});

// Reducer
export default slice.reducer;
