import PropTypes from "prop-types";
import { createContext, useEffect, useReducer, useCallback } from "react";
// utils
import axios from "../utils/axios";
//
import { isValidToken, setSession } from "./utils";
//qs
import qs from "qs";
import { toast } from "react-toastify";
// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  isRegister: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isRegister: false,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGIN") {
    return {
      ...state,
      isAuthenticated: true,
      isRegister: true,
      email: action.payload.email,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      phoneNumber: action.payload.phoneNumber,
      profilePictureUrl: action.payload.profilePictureUrl,
      id: action.payload.id,
    };
  }
  if (action.type === "REGISTER") {
    return {
      ...state,
      isAuthenticated: false,
      isRegister: true,
      user: null,
    };
  }
  if (action.type === "LOGOUT") {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const Token =
        typeof window !== "undefined" ? localStorage.getItem("Token") : "";

      if (Token && isValidToken(Token)) {
        setSession(Token);
      } else {
        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "INITIAL",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async (data) => {
    try {
      const formData = qs.stringify({
        ...data,

        grant_type: "password",

        client_id: "chPDieeXZVCJ4MuMkxs2KxFMXl3QVBFSEdJPT3gd",

        client_secret:
          "qbgwOfhkxOIW7pKdfP29DtJ2WNxpCjvvn3qDUCTtOYqs4szsbfCSIYDV86FbpctjQrIb2r60Sc3FH5ZskSHRYYISaKEODGA5cJ7eynlFZlRdzDyj6UGUWoUi1OyuqVjw",
      });
      const response = await axios.post("/auth/token/", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log(response);
      setSession(response?.access_token);

      if (response.access_token) {
        window.location.href = "/dashboard";
      } else {
        toast("error");
      }
    } catch (error) {
      throw error;
    }
  };

  // LOGOUT
  const logout = async () => {
    localStorage.clear();
    setSession(null);
    dispatch({
      type: "LOGOUT",
    });
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
