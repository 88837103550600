// ----------------------------------------------------------------------

const ar = {
  login: `تسجيل الدخول`,
  email_is_required: `الايميل مطلوب`,
  password_is_required: `كلمة السر مطلوبة`,
  email_must_be_a_valid_email_address: `يجب أن يكون البريد الإلكتروني عنوان بريد إلكتروني صالحًا`,
  phone_number: `رقم الهاتف`,
  password: `الرقم السري`,
  enter_phone_number: `ادخل رقم الهاتف`,
  enter_password: `ادخل الرقم السري`,
  en: `انكليزي`,
  ar: `عربي`,
  search: `البحث`,
  students: `الطلاب`,
  teachers: `المعلمين`,
  employee: `الموظفين`,
  settings: `الاعدادات`,
  dashboard: `لوحة التحكم`,
  //students
  id: "الرقم مميز",
  first_name: `الاسم الأول `,
  last_name: `الكنية `,
  add_new_student: `إضافة طالب جديد`,
  father_name: `اسم الاب`,
  father_name_is_required: `اسم الاب مطلوب`,
  mother_name: `اسم الام`,
  mother_name_is_required: `اسم الام مطلوب`,
  year_of_birth_is_required: `تاريخ الميلاد مطلوب`,
  date_of_birth: `تاريخ الميلاد`,
  gender: `النوع`,
  mobile_phone: `رقم الموبايل`,
  delete: `حذف`,
  edit: `تعديل`,
  show: `عرض`,
  delete_item: `حذف عنصر`,
  are_you_sure_you_want_to_delete_this_item: `هل انت متأكد من رغبتك بحذف هذا العنصر`,
  cancel: `اغلاق`,
  year_of_birth: `تاريخ الميلاد`,
  first_name_is_required: `الاسم الاول مطلوب`,
  last_name_is_required: `الاسم الاخير مطلوب`,
  send: `ارسال`,
  edit_student: `تعديل معلومات الطالب`,
  class: `الصف`,
  enter_first_name: `ادخل الاسم الاول`,
  enter_last_name: `ادخل الكنية`,
  enter_class: `ادخل الصف`,
  enter_gender: `ادخل النوع`,
  enter_country: `ادخل المحافظة`,
  enter_name_univercity: `ادخل اسم الجامعة`,
  enter_specialization: `ادخل التخصص`,
  action: `الخيارات`,

  // teachers
  add_new_teacher: `اضافة مدرس جديد`,
  edit_teacher: `تعديل معلومات المدرس`,
  country: `المحافظة`,
  name_univercity: `اسم الجامعة`,
  study: `المؤهل الدراسي`,
  specialization: `التخصص الدراسي`,
  stages: `المراحل الدراسية`,
  subjects: `المواد الدراسية`,
  experience: `سنوات الخبرة`,
  cv: `السيرة الذاتية`,
  reject: `رفض`,
  approve: `موافقة`,
  courses: `الكورسات`,
  exams: `الامتحانات`,
  lessons: `الدروس`,
  rating: `التقييمات`,
  add_your_lesson_schedule: "قم بإضافة جدول دروسك",
  teacher_details: `تفاصيل المدرس`,
  name_of_teacher: `اسم المدرس`,
  show_notes: `عرض الملاحظات`,
  notes: `الملاحظات`,
  name_stu: `اسم الطالب`,
  note: `الملاحظة`,
  description: `الوصف`,
  enter_description: `ادخل الوصف`,

  //subjects
  add_new_subject: `اضافة مادة جديدة`,
  edit_subject: `تعديل مادة`,
  arabic_name: `الاسم بالعربي`,
  en_name: `الاسم بالانكليزي`,
  code: `الرمز`,
  enter_arabic_name: `ادخل الاسم باللغة العربية`,
  enter_en_name: `ادخل الاسم باللغة الانكليزية`,
  enter_code: `ادخل الرمز`,

  //Courses
  add_url: `إضافة رابط`,
  add_record_lessons: `إضافة روابط التسجيل`,
  url: `الرابط`,
  enter_url: `ادخل الرابط`,
  add_new_cource: `اضافة كورس جديد`,
  edit_cource: `تعديل كورس`,
  cource_name: `اسم الكورس`,
  enter_cource_name: `ادخل اسم الكورس`,
  status: `الحالة`,
  classification: `التصنيف`,
  t_name: `اسم الاستاذ`,
  number_s: `عدد المقالات`,
  number_l: `عدد الدروس`,
  create_date: `تاريخ الانشاء`,
  cources: `الكورسات`,
  unsubscribe: `الغاء الاشتراك`,
  add_course: `اضافة كورس`,
  course_name: `اسم الكورس`,
  enter_course_name: `ادخل اسم الكورس`,
  classroom: `الصف الدراسي`,
  show_lessons: `عرض الدروس`,
  show_all_students: `عرض جميع الطلاب`,
  lesson_name: `اسم الدرس`,
  add_new_lesson: `اضافة درس جديد`,
  enter_lesson_name: `ادخل اسم الدرس`,
  saturday: `السبت`,
  sunday: `الاحد`,
  monday: `الاثنين`,
  tuesday: `الثلاثاء`,
  wednesday: `الاربعاء`,
  thursday: `الخميس`,
  friday: `الجمعة`,
  days: `الايام`,
  from: `من`,
  to: `إلى`,
  class_times: `مواعيد الحصص`,

  // parents
  parents: `اولياء الامور`,
  add_new_parent: `اضافة اهل`,
  edit_parent: `تعديل معلومات الاهل`,
  user_name: `اسم المستخدم`,
  email: `الايميل`,
  phone: `رقم الهاتف`,
  city: `المدينة`,
  enter_email: `ادخل الايميل`,
  enter_phone: `ادخل رقم الهاتف`,

  // employees
  employees: `الموظفين`,
  add_new_employee: `اضافة موظف`,
  edit_employee: `تعديل موظف`,
  employee_name: `اسم الموظف`,

  // grades
  grades: `العلامات`,
  add_new_grade: `اضافة علامة جديدة`,
  edit_grade: `تعديل العلامة`,
  name_sub: `اسم المادة`,
  grade: `العلامة`,
  enter_name_sub: `ادخل اسم المادة`,
  enter_grade: `ادخل العلامة`,

  //Content management
  content_management: `ادارة المحتوى`,

  // articals
  articals: `المقالات`,
  add_new_artical: `اضافة مقال جديد`,
  edit_artical: `تعديل مقال`,
  enter_artical: `ادخل المقال هنا`,
  artical: `المقالة`,

  //Contests
  competintions: `المسابقات`,
  add_contests: `إضافة مسابقة`,
  edit_contests: `تعديل مسابقة`,
  c_name: `اسم المسابقة`,
  enter_name: `ادخل اسم المسابقة`,
  start_time: `وقت البدأ`,
  end_time: ` وقت النهاية`,
  number_of_views: `عدد المشاهدات  `,
  number_of_questions: `عدد الأسئلة  `,

  //Pending orders
  pending_orders: `الطلبات المعلقة`,
  pending_orders_t: `طلبات الاساتذة المعلقة`,
  accepted: `قبول`,
  are_you_sure_you_accept_this_teacher: `هل انت متاكد من قبول هذا المعلم ؟`,
  added_successfully: `تم قبول المعلم بنجاح.`,
  pending_teachers: `طلبات الاساتذة المعلقة`,
  pending_parents: `طلبات الاهالي المعلقة`,
  full_name: `الاسم الكامل`,

  // overView
  over_view: `لوحة التحكمْ`,
  statistics: `إحصائيات`,
  number_student: `عدد المقالات`,
  number_clients: `عدد العملاء`,
  number_parents: `عدد اولياء الامور`,
  number_em: `عدد الموظفين`,
  number_les: `عدد الدروس`,

  // profile
  profile: `الملف الشخصي`,

  // Forum
  forum: `المنتدى`,
  add_new_forum: `اضافة منتدى جديد`,
  edit_forum: `تعديل منتدى`,
  number_q: `عدد الاسئلة`,
  my_questions: `اسألتي`,
  my_answers: `اجوبتي`,
  username: `اسم المستخدم`,

  //student cources
  student_cources: "كورسات الطالب",
  finished_cources: `الكورسات المنتهية`,
  currents_cources: "الكورسات الحالية",
  student: `طالب`,
  teacher: `مدرس`,
  cource: `كورس`,
  parent: `ولي امر`,
  employe: `الموظفين`,
  subject: `مادة دراسية`,
  artica: `مقال`,
  contest: `المسابقات`,
  course: `كورس`,
  order: `طلب`,
  duration: `المدة`,
  duration_unit: `واحدة المدة`,
  logout: `تسجيل الخروج`,
  t_id: `الرقم المميز للاستاذ`,

  //codes
  codes: `الاكواد`,
  add_new_code: `اضافة كود جديد`,
  edit_code: `تعديل كود`,
  c: `كود`,
  amount: `المبلغ`,
  expire_date: `تاريخ انتهاء الصلاحية`,
  serial_number: `الرقم التسلسلي`,
  number_card: `عدد الكاردات`,
  enter_number_card: `ادخل عدد الكاردات`,
  enter_amount: `ادخل الكمية`,
  months_expire: `أشهر لانتهاء الصلاحية`,
  enter_months_expire: `ادخل أشهر لانتهاء الصلاحية`,
  cancel_the_students_participation_in_the_course: `الغاء اشتراك الطالب بالكورس`,
  are_you_sure_you_want_to_cancel_the_students_participation: `هل انت متأكد من الغاء اشتراك الطالب بالكورس`,
  title: `العنوان`,
  enter_title: `ادخل العنوان`,
  close: `اغلاق`,
  school_name: `اسم المدرسة`,
  created_date: `تاريخ الانشاء`,
  directorate: `مديرية`,
  total_quizzes: `مجموع الاختبارات`,
  answered_quizzes: `الاختبارات المجاب عليها`,
  governorate: `المحافظة`,
  add: `اضافة`,
  add__lesson_schedule: `قم بإضافة جدول دروسك `,
  price: `السعر`,
  enter_price: `ادخل السعر`,
  upload_file: `رفع ملف`,
  number_of_course: `عدد الكورسات`,
  number_of_lessons: `عدد الدروس`,
  number_of_exams: `عدد الامتحانات`,
  number_of_students: `عدد المقالات`,
  total_earnings: `مجموع الارباح`,
  start_date: `تاريخ البدء`,
  end_date: `تاريخ الانتهاء`,
  start_time: `وقت البدء`,
  end_time: `وقت الانتهاء`,
  No_data_found_till_now: `لا يوجد بيانات حتى الان`,
  content: `المحتوى`,
  photo: `الصورة`,
  change_course_price: `تغيير سعر الكورس`,
  enter_new_price: `أدخل السعر الجديد  `,
  change_price: `تغيير السعر `,
  earnings: `الأرباح`,
  total_balance: `الرصيد الإجمالي `,
  current_month_earnings: `أرباح الشهر الحالي`,
  average_earning_per_course: `متوسط ​​الدخل لكل دورة`,
  total_earnings: `إجمالي الأرباح`,
  total_withdrawals: `إجمالي السحوبات`,
  last_withdrawal: `آخر سحب`,
  edit_wallet: `تعديل محفظة`,
  study_level: "الصف الدراسي",
  loading: `جاري تحميل البيانات`,
  print: `طباعة`,
  export: `تصدير`,
  name: "الاسم",
  // actions
  enter_lesson_name: "ادخل اسم الدرس",
  save: "حفظ",
  list_of_lessons: "قائمة الدروس",
  enter_start_date: "حدد تاريخ البدء",
  edit_price: "تعديل السعر",
  edit_expire_date: "تعديل تاريخ الإنتهاء",
  enter_expire_date: "ادخل تاريخ انتهاء الصلاحية",
  subscripe_in_cource: "اشترك في كورس",
  supervisor: `المشرفون`,
  add_new_supervisor: `إضافة المشرف  `,
  birthdate: `تاريخ الميلاد`,
  course_name_required: `اسم الكورس مطلوب`,
  course_subject_required: `المادة مطلوبة`,
  price_required: `السعر مطلوب`,
  course_description_required: `الوصف مطلوب`,
  questions: `بنك الأسئلة`,
  add_new_question: "أضف سؤال جديد",
  questionText: "السؤال",
  enter_questionText: "ادخل السؤال",
  answer: "الإجابة",
  enter_answer: " ادخل الإجابة",
  options: "الخيارات",
  points: "النقاط",
  enter_points: "ادخل النقاط",
  qustion_type: "نوع السؤال",
  enter_option: "ادخل اختيار",
  link: `الرابط`,
  google_meet_link: `روابط جوجل ميت`,
  google_drive_link: ` الرابط الحالي : `,
  no_link_yet: `لم يتم إضافة رابط بعد`,
  add_assistant_to_teacher: `إضافة مساعد للأستاذ`,
  teacher_assistent: `مساعدو الأستاذ`,
  add_new_assistant: `إضافة مساعد الأستاذ`,
  question_text: `نص السؤال`,
  answer: `الإجابة`,
  options: `الخيارات`,
  points: `النقاط`,
  question_of_comp: `أسئلة المسابقة  `,
  add_question: `إضافة سؤال `,
  school_stage: `الصف الدراسي `,
  teacher_name: `اسم الصف `,

  ///////////////////////////////////////////////////////////////
  about_us: `حول`,
  blogs: `المقالات`,
  clients: `العملاء`,
  contact_us: `تواصل معنا`,
  jobs: `العمل`,
  office: `المكتب`,
  people_type: `أنماط الأشخاص `,
  people: `الأشخاص`,
  area: `المناطق`,
  testimonial: `شهادة`,
  testimonial_people: `شهادة الأشخاص `,
  title_ar: `العنوان بالعربي`,
  title_en: `العنوان بالإنكليزي `,
  details_ar: `التفاصيل بالعربي `,
  details_en: `التفاصيل بالانكليزي `,
  image: `الصورة`,
  show_in_home_page: `المشاهدة في الصفحة الرئيسية`,
  add: `إضافة`,
  add_blog: `إضافة مقال `,
  done: `تم`,
  add_client: `إضافة عميل`,
  address: `العنوان`,
  educational_degree: `الدرجة التعليمية`,
  about_applier: `About Applier`,
  company_name_ar: `اسم الشركة بالعربي`,
  company_name_en: `اسم الشركة بالانكليزي`,
  address_ar: `العنوان بالعربي`,
  address_en: `العنوان بالانكليزي`,
  facebook: `فيسبوك`,
  instagram: `انستاغرام`,
  twitter: `تويتر`,
  whatsapp: `وتساب`,
  rate: `التقييم`,
  name_ar: `الاسم بالعربي `,
  name_en: `الاسم بالانكليزي `,
  counts_details_ar: `التعداد اللغة العربية`,
  counts_details_en: `التعداد باللغة الانكليزية  `,
  type: `النمط`,
  created_at: ` تم الإنشاء`,
  privacy_policy: `سياسة الخصوصية `,
  cookie_policy: `سياسة ملفات تعريف الارتباط `,
  terms_conditions: ` الشروط والأحكام `,
  add_paragraph: `إضافة فقرة `,
  add_text: `إضافة نص `,
  edit_pharaghraph: `تعديل فقرة`,
  edit_text: `تعديل نص`,
  main_sliders:`سلايدر الصفحة الرئيسية `,
  link:`الرابط`
};

export default ar;
