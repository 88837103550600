import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET TESTIONILA PEOPLE
export const getTestimonialPeopleService = createAsyncThunk(
  "testimonial/getTestimonialPeopleService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.testimonialPeopleURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  POST TESTIOMNAL
export const createTestimonialPeopleService = createAsyncThunk(
  "testimonial/createTestimonialPeopleService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createTestimonialPeopleURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// PUT =>  PUT TESTIOMNAL
export const updateTestiomnalPeopleService = createAsyncThunk(
  "testimonial/updateTestiomnalPeopleService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.testimonialPeopleURL}${params.id}/update/`,
        params?.data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  PUT TESTIOMNAL
export const getTestiomnalByIdService = createAsyncThunk(
  "testimonial/getTestiomnalByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.testimonialPeopleURL}${id}/show/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// DELETE =>  DELETE TESTIOMNAL
export const deleteTestiomnalPeopleService = createAsyncThunk(
  "testimonial/deleteTestiomnalPeopleService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${URLS.testimonialPeopleURL}${id}/delete/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET TESTIONILA
export const getTestimonialService = createAsyncThunk(
  "testimonial/getTestimonialService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.testimonialURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  PUT TESTIOMNAL
export const updateTestiomnalService = createAsyncThunk(
  "testimonial/updateTestiomnalService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.updateTestimonialURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
