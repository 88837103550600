import { createSlice } from "@reduxjs/toolkit";
import {
  getKeyPracticeAreaService,
  getTopicService,
  updateKeyPracticeAreaService,
  createTopicService,
  deleteTopicService,
  getTopicByIdService,
  updateTopicService,
  getContentsService,
  deleteContentService,
  getContentByIdService,
} from "../services/key_pracitce_area_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingkeyPracticeAreaInfo: false,
  keyPracticeAreaInfo: {},
  isLoadingUpdate: false,
  topicId: null,
  topicInfo: null,
  topics: null,
  isLoading: false,
  metaTopic: null,
  isLoadingCreate: false,
  isLoadingDelete: false,
  isLoadingUpdate: false,
  contents: [],
  metaContents: null,
  contentId: null,
  contentInfo: null,
};

const slice = createSlice({
  name: "key_practice_area",
  initialState,
  reducers: {
    setTopicId: (state, action) => {
      state.topicId = action.payload;
    },
    setTopicInfo: (state, action) => {
      state.topicInfo = action.payload;
    },
    setContentId: (state, action) => {
      state.contentId = action.payload;
    },
    setContentInfo: (state, action) => {
      state.contentInfo = action.payload;
    },
  },
  extraReducers: {
    // GET =>  GET KEY PRACTICE AREA
    [getKeyPracticeAreaService.fulfilled]: (state, action) => {
      state.isLoadingkeyPracticeAreaInfo = false;
      state.keyPracticeAreaInfo = action.payload?.data;
    },
    [getKeyPracticeAreaService.rejected]: (state, action) => {
      state.isLoadingkeyPracticeAreaInfo = false;
    },
    [getKeyPracticeAreaService.pending]: (state) => {
      state.isLoadingkeyPracticeAreaInfo = true;
    },

    // PUT =>  PUT  KEY PRACTICE AREA
    [updateKeyPracticeAreaService.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateKeyPracticeAreaService.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateKeyPracticeAreaService.pending]: (state) => {
      state.isLoadingUpdate = true;
    },

    // GET =>  GET TOPICS SERVICE
    [getTopicService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.topics = action.payload.data;
      state.metaTopic = action.payload;
    },
    [getTopicService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTopicService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET =>  CREATE TOPIC
    [createTopicService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createTopicService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createTopicService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // GET =>  CREATE TOPIC
    [deleteTopicService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteTopicService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteTopicService.pending]: (state) => {
      state.isLoadingDelete = true;
    },

    // GET =>  GET TOPIC BY IID
    [getTopicByIdService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
      state.topicInfo = action.payload.data;
    },
    [getTopicByIdService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [getTopicByIdService.pending]: (state) => {
      state.isLoadingDelete = true;
    },

    // GET =>  GET TOPIC BY IID
    [updateTopicService.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateTopicService.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateTopicService.pending]: (state) => {
      state.isLoadingUpdate = true;
    },

    // GET =>  GET CONTENTS
    [getContentsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.contents = action.payload?.data;
      state.metaContents = action.payload;
    },
    [getContentsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getContentsService.pending]: (state) => {
      state.isLoading = true;
    },

    // DELETE =>  DELETE CONTENT
    [deleteContentService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteContentService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteContentService.pending]: (state) => {
      state.isLoadingDelete = true;
    },

      // GET =>  GET CONTENT BY ID
      [getContentByIdService.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.contentInfo=action.payload?.data
      },
      [getContentByIdService.rejected]: (state, action) => {
        state.isLoading = false;
      },
      [getContentByIdService.pending]: (state) => {
        state.isLoading = true;
      },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setTopicId, setTopicInfo, setContentId, setContentInfo } =
  slice.actions;
