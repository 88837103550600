import { Navigate, useRoutes } from "react-router-dom";
//layout
import DashboardLayout from "../layouts/dashboard/DashboardLayout";

//
import {
  LoginPage,
  OverviewPage,

  ////////////////////////////////////////////
  AboutusPage,
  CreateAboutusPage,
  EditAboutUsPage,
  AboutusDetailes,
  BlogsPage,
  CreateBlogPage,
  CreateClientPage,
  ClientsPage,
  ContactUsPage,
  CreateContactUsPage,
  JobsPage,
  CreateJobPage,
  PeopleTypesPage,
  OfficeDetailesPage,
  CreatePeopleTypePage,
  TestimonioalPage,
  CreateTestimonioalPeoplePage,
  EditBlogPage,
  EditClientPage,
  EditPeopleTypePage,
  PeoplesPage,
  CreatePeoplePage,
  EditPeoplePage,
  TestimonialDetailesPage,
  EditTestimonioalPage,
  KeyPracticeArePage,
  TopicsPage,
  CreateTopicPage,
  EditTopicPage,
  ContentPage,
  CreateCountsPage,
  CreatePharaghraphPage,
  CreateTextPage,
  EditCountsPage,
  EditPharaghraphPage,
  EditTextPage,
  PrivacyPlolicyPage,
  CookiePlolicyPage,
  TermsConditionsPage,
  MainSlidersPage,
  CreateMainSliderPage,
  EditMainSliderPage
} from "./elements";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: "/",
      children: [
        {
          element: <Navigate to="auth/login" replace />,
          index: true,
        },
        { path: "auth/login", element: <LoginPage /> },
      ],
    },

    // =============  @@@@ ADMIN @@@@ =========
    // Dashboard
    {
      path: "dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/about_us" replace />, index: true },
        { path: "about_us", element: <AboutusPage /> },

        //about_us
        {
          path: "about_us",
          children: [
            { path: "", element: <AboutusPage /> },
            { path: "create-aboutus", element: <CreateAboutusPage /> },
            { path: "edit", element: <EditAboutUsPage /> },
            /*   { path: "aboutus-detailes", element: <AboutusDetailes /> },*/
          ],
        },

         //about_us
         {
          path: "main_sliders",
          children: [
            { path: "", element: <MainSlidersPage /> },
            { path: "create-main-slider", element: <CreateMainSliderPage /> },
            { path: "edit", element: <EditMainSliderPage /> },
            /*   { path: "aboutus-detailes", element: <AboutusDetailes /> },*/
          ],
        },

        //blogs
        {
          path: "blogs",
          children: [
            { path: "", element: <BlogsPage /> },
            { path: "create-blog", element: <CreateBlogPage /> },
            { path: "edit", element: <EditBlogPage /> },
            /*  { path: "aboutus-detailes", element: <AboutusDetailes /> },*/
          ],
        },

        //clients
        {
          path: "clients",
          children: [
            { path: "", element: <ClientsPage /> },
            { path: "create-client", element: <CreateClientPage /> },
            { path: "edit", element: <EditClientPage /> },
            /*  { path: "aboutus-detailes", element: <AboutusDetailes /> },*/
          ],
        },
        {
          path: "contact",
          children: [
            { path: "", element: <ContactUsPage /> },
            { path: "create-contact", element: <CreateContactUsPage /> },
            /*  { path: "edit-aboutus", element: <EditAboutUsPage /> },
                  { path: "aboutus-detailes", element: <AboutusDetailes /> },*/
          ],
        },
        {
          path: "jobs",
          children: [
            { path: "", element: <JobsPage /> },
            { path: "create-job", element: <CreateJobPage /> },
            /*  { path: "edit-aboutus", element: <EditAboutUsPage /> },
                  { path: "aboutus-detailes", element: <AboutusDetailes /> },*/
          ],
        },

        {
          path: "people",
          children: [
            { path: "", element: <PeoplesPage /> },
            { path: "create-people", element: <CreatePeoplePage /> },
            { path: "edit", element: <EditPeoplePage /> },
            /*    { path: "aboutus-detailes", element: <AboutusDetailes /> },*/
          ],
        },
        {
          path: "people-type",
          children: [
            { path: "", element: <PeopleTypesPage /> },
            { path: "create-people-type", element: <CreatePeopleTypePage /> },
            { path: "edit", element: <EditPeopleTypePage /> },
            /*    { path: "aboutus-detailes", element: <AboutusDetailes /> },*/
          ],
        },
        {
          path: "office",
          children: [
            { path: "", element: <OfficeDetailesPage /> },

            /*  { path: "edit-aboutus", element: <EditAboutUsPage /> },
                  { path: "aboutus-detailes", element: <AboutusDetailes /> },*/
          ],
        },

        {
          path: "area",
          children: [
            { path: "", element: <KeyPracticeArePage /> },

            /*  { path: "edit-aboutus", element: <EditAboutUsPage /> },
                  { path: "aboutus-detailes", element: <AboutusDetailes /> },*/
          ],
        },
        {
          path: "topics",
          children: [
            { path: "", element: <TopicsPage /> },

            { path: "create-topic", element: <CreateTopicPage /> },
            { path: "edit", element: <EditTopicPage /> },
            { path: "content", element: <ContentPage /> },
            //add-count
            { path: "create-count", element: <CreateCountsPage /> },
            //create-paragraph
            { path: "create-paragraph", element: <CreatePharaghraphPage /> },
            //CreateTextPage
            { path: "create-text", element: <EditTextPage /> },

             //add-count
             { path: "edit-count", element: <EditCountsPage /> },
             //create-paragraph
             { path: "edit-paragraph", element: <EditPharaghraphPage /> },
             //CreateTextPage
             { path: "edit-text", element: <EditTextPage /> },
          ],
        },
        {
          path: "testimonial",
          children: [{ path: "", element: <TestimonialDetailesPage /> }],
        },
        {
          path: "testimonia_people",
          children: [
            { path: "", element: <TestimonioalPage /> },

            {
              path: "create-testimonia-people",
              element: <CreateTestimonioalPeoplePage />,
            },
            { path: "edit", element: <EditTestimonioalPage /> },
          ],
        },

        {
          path: "privacy_policy",
          children: [{ path: "", element: <PrivacyPlolicyPage /> }],
        },

        {
          path: "cookie_policy",
          children: [{ path: "", element: <CookiePlolicyPage /> }],
        },

        {
          path: "terms_conditions",
          children: [{ path: "", element: <TermsConditionsPage /> }],
        },
      ],
    },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
