import axios from "axios";
// config
// import { HOST_API_KEY } from '../config';
import { errorToast } from "./toastMessage";

// ----------------------------------------------------------------------
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_APP_BASE_URL,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    "Accept-Language": localStorage.getItem("i18nextLng"),
    Authorization: `Bearer ${localStorage.getItem("Token")}`,
  },

  // timeout: 4000,
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (
      response.status === 200 &&
      (response.data.message === "Success" ||
        response.data.message === "تمت العملية بنجاح")
    ) {
      return response.data;
    } else if (response.status === 200 && response.data.status !== 200) {
      return response.data;
    }
    return response.data;
  },
  (error) => {
    if (error.response.status === 400) {
      throw error?.response?.data?.errors?.map((error, index) =>
        errorToast(error)
      );
    }
    if (error.response.status == 401) {
      localStorage.clear();
      window.location.href="/"
      throw errorToast(error.response.statusText);
    } else if (error.response.status === 402) {
      throw error;
    } else if (
      error.response.status === 406 ||
      error.response.status === 422 ||
      error.response.status === 419
    ) {
      if (
        error.response.data.message === "" ||
        error?.response?.data?.data?.account_found_nd_profile_not_created
      ) {
      } else {
        // console.log(error.response.statusText);
        console.log(error.response.data.message);
        errorToast(error.response.data.message);
      }
      throw error;
    } else {
      console.log(error.response.statusText);
      throw errorToast(error.response.statusText);
    }
  }
);
export default axiosInstance;
