import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET HOME
export const getHomeService = createAsyncThunk(
  "home/getHomeService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.homeURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  POST HOME
export const createHomeService = createAsyncThunk(
  "home/createHomeService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createHomeURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// PUT =>  PUT HOME
export const updateHomeService = createAsyncThunk(
  "home/updateHomeService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.homeURL}${params.id}/update/`,
        params?.data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET HOME BY ID SERVICE
export const getHomeByIdService = createAsyncThunk(
  "home/getHomeByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.homeURL}${id}/show/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// DELETE =>  DELETE HOME
export const deleteHomeService = createAsyncThunk(
  "home/deleteHomeService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.homeURL}${id}/delete/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
