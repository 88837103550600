import { createSlice } from "@reduxjs/toolkit";
import {
  getTermsConditionsService,
  updateTermsConditionsService,
  getPrivacyPolicyService,
  updatePrivacyPloicyService,
  getCookiePloicyService,
  updateCookiePloicyService
} from "../services/general_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingOfficeInfo: false,
  termsInfo: {},
  privacyInfo: {},
  cookiesInfo:{},
  isLoadingUpdate: false,
};

const slice = createSlice({
  name: "general",
  initialState,
  reducers: {},
  extraReducers: {
    // GET =>  GET TERMS AND CONDITIONS
    [getTermsConditionsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.termsInfo = action.payload?.data;
    },
    [getTermsConditionsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTermsConditionsService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET =>  GET PRIVACY 
    [updateTermsConditionsService.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateTermsConditionsService.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateTermsConditionsService.pending]: (state) => {
      state.isLoadingUpdate = true;
    },

  // GET =>  GET PRIVACY 
     [getPrivacyPolicyService.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.privacyInfo = action.payload?.data;
      },
      [getPrivacyPolicyService.rejected]: (state, action) => {
        state.isLoading = false;
      },
      [getPrivacyPolicyService.pending]: (state) => {
        state.isLoading = true;
      },
  
      // UPDATE =>  UPDATE PRIVACY 
      [updatePrivacyPloicyService.fulfilled]: (state, action) => {
        state.isLoadingUpdate = false;
      },
      [updatePrivacyPloicyService.rejected]: (state, action) => {
        state.isLoadingUpdate = false;
      },
      [updatePrivacyPloicyService.pending]: (state) => {
        state.isLoadingUpdate = true;
      },


      // GET =>  GET COOKEI 
     [getCookiePloicyService.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.cookiesInfo = action.payload?.data;
      },
      [getCookiePloicyService.rejected]: (state, action) => {
        state.isLoading = false;
      },
      [getCookiePloicyService.pending]: (state) => {
        state.isLoading = true;
      },
  
      // UPDATE =>  UPDATE COOKIE 
      [updateCookiePloicyService.fulfilled]: (state, action) => {
        state.isLoadingUpdate = false;
      },
      [updateCookiePloicyService.rejected]: (state, action) => {
        state.isLoadingUpdate = false;
      },
      [updateCookiePloicyService.pending]: (state) => {
        state.isLoadingUpdate = true;
      },
  },
});

// Reducer
export default slice.reducer;
