import { createSlice } from "@reduxjs/toolkit";
import {
  getJobsService,
  createJobService,
  getJobDetailesService,
} from "../services/jobs_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingJobs: false,
  jobs: [],
  totalElements: null,
  isLoadingDelete: false,
  isLoadingUpdate: false,
  metaJob:null
};

const slice = createSlice({
  name: "blogs",
  initialState,
  reducers: {},
  extraReducers: {
    // GET =>  GET JOBS
    [getJobsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.jobs = action.payload?.data;
      state.metaJob = action.payload;
    },
    [getJobsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getJobsService.pending]: (state) => {
      state.isLoading = true;
    },

    // CREATE =>  CREATE JOB
    [createJobService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createJobService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createJobService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
  },
});

// Reducer
export default slice.reducer;


