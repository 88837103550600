// ----------------------------------------------------------------------

const en = {
  login: `Login`,
  // required
  email_is_required: `Email is required`,
  password_is_required: `Password is required`,
  email_must_be_a_valid_email_address: `Email must be a valid email address`,
  phone_number: `Phone number`,
  password: `Password`,
  enter_phone_number: `Enter phone number`,
  enter_password: `Enter password`,
  en: `English`,
  ar: `Arabic`,
  search: `Search`,
  students: `Students`,
  teachers: `Teachers`,
  employee: `Employees`,
  settings: `Settings`,
  dashboard: `Dashboard`,
  //students
  id: "Id",
  first_name: `First Name`,
  last_name: `Last Name`,
  add_new_student: `Add New Student`,
  father_name: `Father Name`,
  father_name_is_required: `Father name is required`,
  mother_name: `Mother Name`,
  mother_name_is_required: `Mother name is required`,
  year_of_birth_is_required: `year of birth is required`,
  date_of_birth: `Date of birth`,
  gender: `Gender`,
  mobile_phone: `Mobile Phone`,
  delete: `Delete`,
  edit: `Edit`,
  show: `Show`,
  delete_item: `Delete Item`,
  are_you_sure_you_want_to_delete_this_item: `Are you sure you want to delete this item`,
  cancel: `Cancel`,
  year_of_birth: `Year Of Birth`,
  first_name_is_required: `First name is required`,
  last_name_is_required: `Last name is required`,
  send: `send`,
  edit_student: `Edit student`,
  class: `Class`,
  enter_first_name: `Enter first name`,
  enter_last_name: `Enter last name`,
  enter_class: `Enter class`,
  enter_gender: `Enter gender`,
  enter_country: `Enter country`,
  enter_name_univercity: `Enter University Name`,
  enter_specialization: `Enter specialization`,
  action: `actions`,

  // teachers
  add_new_teacher: `Add New Teacher`,
  edit_teacher: `Edit Teacher`,
  country: `Country`,
  name_univercity: `University Name`,
  study: `Educational Qualification`,
  specialization: `Academic specialization`,
  stages: `School Stages`,
  subjects: `Subjects`,
  experience: `Years of Experience`,
  cv: `Cv`,
  reject: `Reject`,
  approve: `Approve`,
  courses: `Courses`,
  exams: `Exams`,
  rating: `rating`,
  teacher_details: `Teacher Details`,
  name_of_teacher: `Name of teacher`,
  show_notes: `Show notes`,
  notes: `Notes`,
  name_stu: `Name student`,
  note: `Note`,
  description: `Description`,
  enter_description: `Enter Description`,

  //subjects
  add_new_subject: `Add new subject`,
  edit_subject: `Edit subject`,
  arabic_name: `Arabic name`,
  en_name: `English name`,
  code: `Code`,
  enter_arabic_name: `Enter arabic name`,
  enter_en_name: `Enter English name`,
  enter_code: `Enter code`,

  //Courses
  add_url: `Add URL`,
  add_record_lessons: `Add Record Lessons`,
  url: `Url`,
  enter_url: `Enter url`,
  add_new_cource: `Add new cource`,
  edit_cource: `Edit cource`,
  cource_name: `Cource name`,
  enter_cource_name: `Enter cource name`,
  status: `Status`,
  classification: `Classification`,
  t_name: `Teacher Name`,
  number_s: `Number Of Student`,
  number_l: `Number Of Lessons`,
  create_date: `Create date`,
  cources: `Cource`,
  unsubscribe: `Unsubscribe`,
  add_course: `Add course`,
  course_name: `Course name`,
  enter_course_name: `Enter course name`,
  classroom: `Classroom`,
  show_lessons: `Show lessons`,
  lesson_name: `lesson name`,
  add_new_lesson: `Add new lesson`,
  enter_lesson_name: `Enter lesson name`,
  saturday: `Saturday`,
  sunday: `Sunday`,
  monday: `Monday`,
  tuesday: `Tuesday`,
  wednesday: `Wednesday`,
  thursday: `Thursday`,
  friday: `Friday`,
  days: `Days`,
  from: `Form`,
  to: `To`,
  class_times: `Class times`,
  add: `Add`,
  add__lesson_schedule: `Add your lesson schedule`,
  price: `price`,
  enter_price: `Enter price`,
  upload_file: `Upload file`,

  // parents
  parents: `Parents`,
  add_new_parent: `Add new parent`,
  edit_parent: `Edit parent`,
  user_name: `User name`,
  email: `Email`,
  phone: `Phone`,
  city: `City`,
  enter_email: `Enter email`,
  enter_phone: `Enter phone`,

  // employees
  employees: `Employees`,
  add_new_employee: `Add new employee`,
  edit_employee: `Edit employee`,
  employee_name: `Employee name`,

  // grades
  grades: `Grades`,
  add_new_grade: `Asdd new grade`,
  edit_grade: `Edit grade`,
  name_sub: `Name of subject`,
  grade: `Grade`,
  enter_name_sub: `Enter name of subject`,
  enter_grade: `Enter grade`,

  //Content management
  content_management: `Content management`,

  // articals
  articals: `Articals`,
  add_new_artical: `Add new artical`,
  edit_artical: `Edit artical`,
  enter_artical: `Enter artical`,
  artical: `Artical`,

  //Contests
  competintions: `Competintions`,
  add_contests: `Add Competintion`,
  edit_contests: `Edit Competintion`,
  c_name: `Competintion name`,
  enter_name: ` Enter Competintion name`,
  start_time: `Start Time`,
  end_time: `End Time`,
  number_of_views: `Number of views`,
  number_of_questions: `Number of questions`,

  //Pending orders
  pending_orders: `Pending orders`,
  pending_orders_t: `Pending orders`,
  accepted: `Accepted`,
  are_you_sure_you_accept_this_teacher: `Are you sure you accept this teacher?`,
  added_successfully: `The teacher has been successfully accepted.`,
  pending_teachers: `Pending teachers requests`,
  pending_parents: `Pending parents requests`,
  full_name: `Full Name`,

  // overView
  over_view: `OverView`,
  statistics: `Statistics`,
  number_student: `Number of Student`,
  number_clients: `Number of Clients`,
  number_parents: `Number of Parents`,
  number_em: `Number of Employee`,
  number_les: `Number of Courses`,

  // profile
  profile: `Profile`,

  // Forum
  forum: `The Forum`,
  add_new_forum: `Add new forum`,
  edit_forum: `Edit forum`,
  number_q: `Number Of questions`,
  my_questions: `My questions`,
  my_answers: `My answers`,
  username: `user name`,

  //student cources
  student_cources: "Student cources",
  finished_cources: "Finished cources",
  currents_cources: "Currents cources",

  student: `Student`,
  teacher: `Teacher`,
  cource: `Course`,
  parent: `Parent`,
  employe: `Employee`,
  subject: `Subject`,
  artica: `Artical`,
  contest: `Contest`,
  course: `Course`,
  order: `Order`,
  duration: `Duration`,
  duration_unit: `Duration unit`,
  logout: `Logout`,
  t_id: `Teacher Id`,

  //codes
  codes: `Codes`,
  add_new_code: `Add new code`,
  edit_code: `Edit code`,
  c: `Code`,
  amount: `Amount`,
  expire_date: `Expire date`,
  serial_number: `Serial number`,
  number_card: `Number of cards`,
  enter_number_card: `Enter number of card`,
  enter_amount: `Enter amount`,
  months_expire: `Months To Expire`,
  enter_months_expire: `Enter months To Expire`,
  cancel_the_students_participation_in_the_course: `Cancel the student’s participation in the course`,
  are_you_sure_you_want_to_cancel_the_students_participation: `Are you sure you want to cancel this student participation in the course`,
  title: `Title`,
  enter_title: `Enter title`,
  close: `close`,
  school_name: `school name`,
  created_date: `created date`,
  directorate: `Directorate`,
  total_quizzes: `total quizzes`,
  answered_quizzes: `answered quizzes`,
  governorate: `Governorate`,
  number_of_course: ` Number Of Courses`,
  content: `Content`,
  photo: `Photo`,
  change_course_price: `Change Course Price`,
  enter_new_price: `Enter New Price`,
  change_price: `Change Price`,
  earnings: `Earnings `,
  total_balance: `Total Balance`,
  current_month_earnings: `Current month earnings`,
  average_earning_per_course: `Average Earning Per Course`,
  total_earnings: `Total Earnings`,
  total_withdrawals: `Total Withdrawals`,
  last_withdrawal: `Last Withdrawal`,
  edit_wallet: `Edit Wallet`,
  study_level: "Study Level",
  loading: `Loading`,
  print: `Print`,
  export: `Export`,
  supervisor: `Supervisors`,
  add_new_supervisor: `Add New Supervisor`,
  birthdate: `Birthdate`,
  course_name_required: `Course Name is required`,
  course_subject_required: `Course Subject is required`,
  price_required: `Price required`,
  course_description_required: `Course Description required`,
  questions: `Questions`,
  link: `Link`,
  google_meet_link: `Google meet links`,
  google_drive_link: `Current Link :`,
  no_link_yet: `No link yet`,
  add_assistant_to_teacher: `Add assistant to teacher`,
  teacher_assistent: `Teacher Assistant`,
  add_new_assistant: `Add new assistant`,
  question_text: `Question Text`,
  answer: `Answer`,
  options: `Options`,
  points: `Points`,
  question_of_comp: `Questions Of Cometintions`,
  add_question: `Add Question`,
  school_stage: `School Stage`,
  teacher_name: `Teacher Name`,

  ///////////////////////////////////////////////////////////////
  about_us: `About Us`,
  blogs: `Blogs`,
  clients: `Clients`,
  contact_us: `Contact Us`,
  jobs: `Jobs`,
  office: `Office`,
  prople_type: `People Type`,
  people: `people`,
  area: `Area`,
  testimonial: `Testimonial`,
  testimonial_people: `Testimonial People`,
  title_ar: `Title Arabic`,
  title_en: `Title English`,
  details_ar: `Details Arabic`,
  details_en: `Details English`,
  image: `Image`,
  show_in_home_page: `Show in home page`,
  add: `Add`,
  add_blog: `Create Blog`,
  done: `Done`,
  add_client: `Add Client`,
  address: `Address`,
  educational_degree: `Educational Degree`,
  about_applier: `About Applier`,
  company_name_ar: `Company Name Arabic`,
  company_name_en: `Company Name Engish`,
  address_ar: `Arabic Address`,
  address_en: ` English Address`,
  facebook: `facebook`,
  instagram: `instagram`,
  twitter: `twitter`,
  whatsapp: `whatsapp`,
  rate: `rate`,
  name_ar: `Name Arabic`,
  name_en: `Name English`,
  peoples: `Peoples`,
  counts_details_ar: `Counts details ar`,
  counts_details_en: `Counts details En`,
  type: `type`,
  created_at:`Created at`,
  privacy_policy:`Privacy Policy`,
  cookie_policy:`Cookie Policy`,
    terms_conditions:`Terms and conditions`,
    add_counts:`Add Counts`,
    add_paragraph:`Add Paragraph`,
    add_text:`Add Text`,
    edit_pharaghraph: `Edit Pharaghraph`,
    edit_text: `Edit Text`,
    main_sliders:`Main Sliders`,
    link:`Link`
};

export default en;
