import React, { useEffect, useState } from "react";
//helemt
import { Helmet } from "react-helmet-async";
// localization
import { useLocales } from "../../locales";
import * as Yup from "yup";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "../../components/image/Image";
// @mui
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  Grid,
} from "@mui/material";
// components
import FormProvider, { RHFTextField } from "../../components/hook-form";
//mui
import { LoadingButton } from "@mui/lab";
// routes
import Iconify from "../../components/iconify";
//toast
import ChangeLanguageBox from "../../sections/login/details/ChangeLanguageBox";
// auth
import { useAuthContext } from "../../auth/useAuthContext";
import { toast } from "react-toastify";
// -----------------------------------------------------------------------------

//* ------- Styles --------
const vectorImgSx = {
  width: "100%",
  height: "100vh",
};

const gridLoginSectionSx = {
  //paddingLeft: "30px",
  //paddingRight: "30px",
  pl: { xs: "50px", sm: "30px", md: "30px" },
  pr: { xs: "50px", sm: "30px", md: "30px" },
};

const displayContainerTitle = {
  display: "flex",
  alignItems: "center",
  marginTop: { xs: "150px", lg: "150px" },
};

const loginTySx = {
  fontSize: "40px",
  fontWeight: 600,
  color: "#142457",
  mb: "20px",
};

const lableSx = {
  fontSize: "14px",
  fontWeight: 400,
  color: "#5E6366",
  paddingBottom: "4px",
};

const txtFieldBorderStyle = {
  width: "100%",
  // boxShadow: "#101828",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "12px",
    // boxShadow: "#101828",
    height: "58px",
    border: "1px solid #CAD0D7",
    "&:hover fieldset": {
      border: "1px solid #CAD0D7",
      borderColor: "#CAD0D7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CAD0D7",
    },
  },
};

const loadingButtonStyle = {
  marginBottom: "23px",
  fontSize: "18px",
  fontWeight: 700,
  //bgcolor: "#227284",
  background: `linear-gradient(90deg,#187FC4 100%,#142B60 100%)`,
  py: "15px",
  color: "#FFFFFF",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#227284",
    color: "#FFFFFF",
  },
  marginLeft: "auto",
  borderRadius: "12px",
  marginRight: "auto",
  height: "58px",
};

const changeLangSx = {
  position: "absolute",
  top: { xs: "50px", md: 0 },
  right: { xs: "10px", md: 0 },
};

const containerImgSx = { display: { xs: "none", md: "flex" } };

const boxGridSx = {
  height: "100%",
  width: "100%",
  position: "relative",
};
//_______________________________________________
export default function LoginPage() {
  const { login } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  const { translate } = useLocales();

  const SignInSchema = Yup.object().shape({
    username: Yup.string().required(translate("username_is_required")),
    password: Yup.string().required(translate("password_is_required")),
  });

  const defaultValues = {
    username: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(SignInSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data);
    } catch (e) {
     toast.error("Invalid credentials given.")
  
    }
  };

  // ------------- JSX Code ----------
  return (
    <>
      <Helmet>
        <title>{translate("login")}</title>
      </Helmet>

      <Grid container height="100%" position="fixed" columnSpacing={12}>
        {/* ************ image Section ************/}
        <Grid item xs={12} sm={12} md={6} sx={{ ...containerImgSx }}>
          <Box sx={boxGridSx}>
            <Image
              src="/assets/icons/bg.svg"
              alt=""
              style={{ ...vectorImgSx }}
            />

            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {" "}
              <img src="/assets/images/logo/logo.svg" alt="" />
            </Box>
          </Box>
        </Grid>

        {/* ********************* Login Form Section ******************/}
        <Grid item xs={12} sm={12} md={6} sx={{ ...gridLoginSectionSx }}>
          <Box sx={{ ...displayContainerTitle }}>
            <Typography sx={{ ...loginTySx }}>{translate("login")}</Typography>
          </Box>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {/*------ background -------- */}

            <Box sx={{ position: "absolute", bottom: 0, right: 0, top: 239 }}>
              <img
                src="/assets/images/login/background.svg"
                alt=""
                style={{ opacity: "0.7" }}
              />
            </Box>
            {/*------ number input -------- */}

            <Typography sx={{ ...lableSx }}>{translate("username")}</Typography>
            <RHFTextField
              name="username"
              placeholder={translate("enter_phone_number")}
              type="text"
              sx={{ ...txtFieldBorderStyle }}
            />
            <Stack mb="24px" />

            {/*------ password input -------- */}

            <Typography sx={{ ...lableSx }}>{translate("password")}</Typography>

            <RHFTextField
              name="password"
              placeholder={translate("enter_password")}
              type={showPassword ? "text" : "password"}
              sx={txtFieldBorderStyle}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-off-fill" : "eva:eye-fill"
                        }
                        color={showPassword ? "#23264E" : "#23264E"}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* -------- Submit Button and sign ip link --------- */}
            <Stack sx={{ marginX: "auto", mt: "30px" }}>
              {/* --- Next Button --- */}
              <LoadingButton
                fullWidth
                disableRipple
                size="large"
                type="submit"
                variant="Contained"
                sx={{
                  ...loadingButtonStyle,
                }}
                loading={isSubmitting}
              >
                {translate("login")}
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Grid>

        {/* -------- Change Language  --------- */}
        <Box sx={{ ...changeLangSx }}>
          <ChangeLanguageBox />
        </Box>
      </Grid>
    </>
  );
}
