import { Suspense, lazy } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------
// AUTH
export const LoginPage = Loadable(
  lazy(() => import("../pages/login/LoginPage"))
);

// Overview Page
export const OverviewPage = Loadable(
  lazy(() => import("../pages/dashboard/overview/OverviewPage"))
);

/////////////////////////////////////////////////////////////////////////////
export const AboutusPage = Loadable(
  lazy(() => import("../pages/dashboard/about-us/AboutusPage"))
);

export const CreateAboutusPage = Loadable(
  lazy(() => import("../pages/dashboard/about-us/CreateAboutusPage"))
);

export const EditAboutUsPage = Loadable(
  lazy(() => import("../pages/dashboard/about-us/EditAboutUsPage"))
);

export const AboutusDetailes = Loadable(
  lazy(() => import("../pages/dashboard/about-us/AboutusDetailes"))
);

export const BlogsPage = Loadable(
  lazy(() => import("../pages/dashboard/blogs/BlogsPage"))
);

export const CreateBlogPage = Loadable(
  lazy(() => import("../pages/dashboard/blogs/CreateBlogPage"))
);

export const ClientsPage = Loadable(
  lazy(() => import("../pages/dashboard/clients/ClientsPage"))
);

export const CreateClientPage = Loadable(
  lazy(() => import("../pages/dashboard/clients/CreateClientPage"))
);

export const ContactUsPage = Loadable(
  lazy(() => import("../pages/dashboard/contact-us/ContactUsPage"))
);

export const CreateContactUsPage = Loadable(
  lazy(() => import("../pages/dashboard/contact-us/CreateContactUsPage"))
);

export const JobsPage = Loadable(
  lazy(() => import("../pages/dashboard/jobs/JobsPage"))
);

export const CreateJobPage = Loadable(
  lazy(() => import("../pages/dashboard/jobs/CreateJobPage"))
);

export const PeopleTypesPage = Loadable(
  lazy(() => import("../pages/dashboard/people-types/PeopleTypesPage"))
);

export const OfficeDetailesPage = Loadable(
  lazy(() => import("../pages/dashboard/office/OfficeDetailesPage"))
);

export const CreatePeopleTypePage = Loadable(
  lazy(() => import("../pages/dashboard/people-types/CreatePeopleTypePage"))
);
export const TestimonioalPage = Loadable(
  lazy(() => import("../pages/dashboard/testimonial/TestimonioalPage"))
);

export const CreateTestimonioalPeoplePage = Loadable(
  lazy(() =>
    import("../pages/dashboard/testimonial/CreateTestimonioalPeoplePage")
  )
);

export const EditBlogPage = Loadable(
  lazy(() => import("../pages/dashboard/blogs/EditBlogPage"))
);

export const EditClientPage = Loadable(
  lazy(() => import("../pages/dashboard/clients/EditClientPage"))
);

export const EditPeopleTypePage = Loadable(
  lazy(() => import("../pages/dashboard/people-types/EditPeopleTypePage"))
);

export const PeoplesPage = Loadable(
  lazy(() => import("../pages/dashboard/people/PeoplesPage"))
);

export const CreatePeoplePage = Loadable(
  lazy(() => import("../pages/dashboard/people/CreatePeoplePage"))
);

export const EditPeoplePage = Loadable(
  lazy(() => import("../pages/dashboard/people/EditPeoplePage"))
);

export const TestimonialDetailesPage = Loadable(
  lazy(() => import("../pages/dashboard/testimonial/TestimonialDetailesPage"))
);

export const EditTestimonioalPage = Loadable(
  lazy(() => import("../pages/dashboard/testimonial/EditTestimonioalPage"))
);

export const KeyPracticeArePage = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/practice-area/key-practice-area/KeyPracticeArePage"
    )
  )
);

export const TopicsPage = Loadable(
  lazy(() => import("../pages/dashboard/practice-area/topic/TopicsPage"))
);

export const CreateTopicPage = Loadable(
  lazy(() => import("../pages/dashboard/practice-area/topic/CreateTopicPage"))
);

export const EditTopicPage = Loadable(
  lazy(() => import("../pages/dashboard/practice-area/topic/EditTopicPage"))
);

export const ContentPage = Loadable(
  lazy(() => import("../pages/dashboard/practice-area/content/ContentPage"))
);

export const CreateCountsPage = Loadable(
  lazy(() => import("../pages/dashboard/practice-area/content/CreateCountsPage"))
);

export const CreatePharaghraphPage = Loadable(
  lazy(() => import("../pages/dashboard/practice-area/content/CreatePharaghraphPage"))
);

export const CreateTextPage = Loadable(
  lazy(() => import("../pages/dashboard/practice-area/content/CreateTextPage"))
);

export const EditCountsPage = Loadable(
  lazy(() => import("../pages/dashboard/practice-area/content/EditCountsPage"))
);

export const EditPharaghraphPage = Loadable(
  lazy(() => import("../pages/dashboard/practice-area/content/EditPharaghraphPage"))
);

export const EditTextPage = Loadable(
  lazy(() => import("../pages/dashboard/practice-area/content/EditTextPage"))
);

export const PrivacyPlolicyPage = Loadable(
  lazy(() => import("../pages/dashboard/privacy-policy/PrivacyPlolicyPage"))
);

export const CookiePlolicyPage = Loadable(
  lazy(() => import("../pages/dashboard/cookie-policy/CookiePlolicyPage"))
);

export const TermsConditionsPage = Loadable(
  lazy(() => import("../pages/dashboard/terms-conditions/TermsConditionsPage"))
);

export const MainSlidersPage = Loadable(
  lazy(() => import("../pages/dashboard/main-sliders/MainSlidersPage"))
);

export const CreateMainSliderPage = Loadable(
  lazy(() => import("../pages/dashboard/main-sliders/CreateMainSliderPage"))
);

export const EditMainSliderPage = Loadable(
  lazy(() => import("../pages/dashboard/main-sliders/EditMainSliderPage"))
);