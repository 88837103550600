import { createSlice } from "@reduxjs/toolkit";
import {
  getBlogsService,
  createBlogService,
  updateBlogService,
  getBlogByIdService,
  deleteBlogService,
} from "../services/blog_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingBlog: false,
  isLoadingCreate:false,
  blogs: [],
  totalElements: null,
  isLoadingDelete: false,
  isLoadingUpdate: false,
  blogId: null,
  metaBlog:null,
  blogInfo:{}
};

const slice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    setBlogId: (state, action) => {
      state.blogId = action.payload;
    },
    setBlogInfo: (state, action) => {
      state.blogInfo = action.payload;
    },
  },
  extraReducers: {
    // ==> GET blogs
    [getBlogsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.blogs = action.payload?.data;
      state.metaBlog = action.payload;
    },
    [getBlogsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getBlogsService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> add blog
    [createBlogService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createBlogService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createBlogService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> update blog
    [updateBlogService.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateBlogService.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateBlogService.pending]: (state) => {
      state.isLoadingUpdate = true;
    },

    // ==> GET BLOG BY ID
    [getBlogByIdService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.blogInfo=action.payload.data
    },
    [getBlogByIdService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getBlogByIdService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> delete blog
    [deleteBlogService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteBlogService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteBlogService.pending]: (state) => {
      state.isLoadingDelete = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setBlogId,setBlogInfo } = slice.actions;
