import { createSlice } from "@reduxjs/toolkit";
import {
  getClientsService,
  createClientService,
  updateClientService,
  getClientByIdService,
  deleteClientService,
} from "../services/clients_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  clients: [],
  totalElements: null,
  isLoadingDelete: false,
  isLoadingUpdate: false,
  clientId: null,
  metaClient:null,
  clientInfo:{}
};

const slice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    setClientInfo: (state, action) => {
      state.clientInfo = action.payload;
    },
  },
  extraReducers: {
    // ==> GET CLIENTS
    [getClientsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.clients = action.payload?.data;
      state.metaClient = action.payload;
    },
    [getClientsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getClientsService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> CREATE CLIENT
    [createClientService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createClientService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createClientService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> UPDATE CLIENT
    [updateClientService.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateClientService.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateClientService.pending]: (state) => {
      state.isLoadingUpdate = true;
    },

      // GET =>  GET Client BY ID
      [getClientByIdService.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.clientInfo=action.payload.data

     
      },
      [getClientByIdService.rejected]: (state, action) => {
        state.isLoading = false;
      },
      [getClientByIdService.pending]: (state) => {
        state.isLoading = true;
      },

    // ==> DELETE CLIENT
    [deleteClientService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteClientService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteClientService.pending]: (state) => {
      state.isLoadingDelete = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setClientId ,setClientInfo} = slice.actions;
