import { combineReducers } from "redux";

import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";

import blogsReducer from "./slices/blogs";

import contactsReducer from "./slices/contacts";

import clientsReducer from "./slices/clients";

import homeReducer from "./slices/home";

import peopleReducer from "./slices/people";

import testimonialReducer from "./slices/testimonial";

import aboutReducer from "./slices/about";

import jobsReducer from "./slices/jobs";

import officeReducer from "./slices/office";

import keyPracitceAreaReducer from "./slices/key_pracitce_area";

import generalReducer from "./slices/general";
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const aboutPersistConfig = {
  key: "about_us",
  storage,
  keyPrefix: "redux-",
  whitelist: ["aboutId", "aboutInfo"],
};
const blogPersistConfig = {
  key: "blog",
  storage,
  keyPrefix: "redux-",
  whitelist: ["blogId", "blogInfo"],
};

const contactsPersistConfig = {
  key: "contacts",
  storage,
  keyPrefix: "redux-",
  whitelist: [""],
};

const clientsPersistConfig = {
  key: "clients",
  storage,
  keyPrefix: "redux-",
  whitelist: ["clientInfo", "clientId"],
};

const peoplePersistConfig = {
  key: "people",
  storage,
  keyPrefix: "redux-",
  whitelist: ["peopleTypeId", "peopleTypeInfo", "peopleInfo"],
};

const testimonialPersistConfig = {
  key: "testimonial",
  storage,
  keyPrefix: "redux-",
  whitelist: ["testimonialId", "testimonialInfo"],
};

const keyPracitceAreaPersistConfig = {
  key: "keyPracitceAreaReducer",
  storage,
  keyPrefix: "redux-",
  whitelist: ["topicId", "topicInfo", "contentId"],
};

//__________________________________________________________________
const rootReducer = combineReducers({
  blogs: persistReducer(blogPersistConfig, blogsReducer),

  contacts: persistReducer(contactsPersistConfig, contactsReducer),

  clients: persistReducer(clientsPersistConfig, clientsReducer),

  home: homeReducer,

  people: persistReducer(peoplePersistConfig, peopleReducer),

  testimonial: persistReducer(testimonialPersistConfig, testimonialReducer),

  about_us: persistReducer(aboutPersistConfig, aboutReducer),

  jobs: jobsReducer,

  office: officeReducer,

  key_practice_area: persistReducer(
    keyPracitceAreaPersistConfig,
    keyPracitceAreaReducer
  ),

  general: generalReducer,
});

export { rootPersistConfig, rootReducer };
