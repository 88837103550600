import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET TERMS CONDITIONS
export const getTermsConditionsService = createAsyncThunk(
  "general/getTermsConditionsService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getTermsConditionsURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// UPDATE =>  UPDATE SERVICE 
export const updateTermsConditionsService = createAsyncThunk(
  "general/updateTermsConditionsService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.updateTermsConditionsURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET PRIVACY 
export const getPrivacyPolicyService = createAsyncThunk(
    "general/getPrivacyPolicyService",
    async (_, thunkAPI) => {
      try {
        const response = await axios.get(URLS.getPrivacyPolicyURL);
        return response;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  
  // UPDATE =>  UPDATE PRIVACY 
  export const updatePrivacyPloicyService = createAsyncThunk(
    "general/updatePrivacyPloicyService",
    async (data, thunkAPI) => {
      try {
        const response = await axios.post(URLS.updatePrivacyPolicyURL, data, {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        });
        return response;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  // GET =>  GET PRIVACY CONDITIONS
export const getCookiePloicyService = createAsyncThunk(
    "general/getCookiePloicyService",
    async (_, thunkAPI) => {
      try {
        const response = await axios.get(URLS.getCookiePloicyURL);
        return response;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  
  // UPDATE =>  UPDATE PRIVACY 
  export const updateCookiePloicyService = createAsyncThunk(
    "general/updateCookiePloicyService",
    async (data, thunkAPI) => {
      try {
        const response = await axios.post(URLS.updateCookiePloicyURL, data, {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        });
        return response;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );