import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET ABOUT US
export const getAboutService = createAsyncThunk(
  "about/getAboutService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.aboutUsURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  POST ABOUT
export const createAboutService = createAsyncThunk(
  "about/createAboutService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.creteAboutUsURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  put ABOUT
export const updateAboutService = createAsyncThunk(
  "about/updateAboutService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.aboutUsURL}${params.id}/update/`,
        params?.data
        , {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET ABOUT BY ID
export const getAboutByIdService = createAsyncThunk(
  "about/getAboutByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.aboutUsURL}${id}/show/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// DELETE =>  DELETE ABOUT
export const deleteAboutService = createAsyncThunk(
  "about/deleteAboutService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.aboutUsURL}${id}/delete/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
