import { createSlice } from "@reduxjs/toolkit";
import {
  getHomeService,
  createHomeService,
  updateHomeService,
  deleteHomeService,
  getHomeByIdService,
} from "../services/home_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  mainSliders: [],
  metaMainSliders: null,
  totalElements: null,
  isLoadingDelete: false,
  isLoadingUpdate: false,
  mainSliderId: null,
  mainSliderInfo: {},
  sliderId: null,
};

const slice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setHomeSliderId: (state, action) => {
      state.mainSliderId = action.payload;
    },
    setHomeSliderInfo: (state, action) => {
      state.mainSliderInfo = action.payload;
    },
  },
  extraReducers: {
    // ==> GET HOME
    [getHomeService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.mainSliders = action.payload?.data;
      state.metaMainSliders = action.payload;
    },
    [getHomeService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getHomeService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> CREATE HOME
    [createHomeService.fulfilled]: (state, action) => {
      state.isLoadingBlog = false;
    },
    [createHomeService.rejected]: (state, action) => {
      state.isLoadingBlog = false;
    },
    [createHomeService.pending]: (state) => {
      state.isLoadingBlog = true;
    },

    // ==> UPDATE HOME
    [updateHomeService.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateHomeService.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateHomeService.pending]: (state) => {
      state.isLoadingUpdate = true;
    },

    // ==> DELETE HOME
    [deleteHomeService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteHomeService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteHomeService.pending]: (state) => {
      state.isLoadingDelete = true;
    },

    // GET =>  GET HOME BY ID
    [getHomeByIdService.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.mainSliderInfo = action.payload?.data;
    },
    [getHomeByIdService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getHomeByIdService.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setHomeSliderId, setHomeSliderInfo } = slice.actions;
