import PropTypes from "prop-types";
// @mui
import { List, Stack } from "@mui/material";
// locales
import { useLocales } from "../../../locales";
//

import NavList from "./NavList";

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

export default function NavSectionVertical({ data, sx, ...other }) {
  const { translate } = useLocales();

  return (
    <Stack>
      <Stack height="26px" />
      <img
        src="/assets/images/logo/logo.svg"
        alt=""
        style={{
          margin: "auto",
          height: "50px",
          // marginLeft: "40px",
          // marginRight: "40px",
        }}
      />
      <Stack height="32px" />
      {data.map((group, index) => {
        //  const key = group.subheader || group.items[0].title;

        return (
          <List key={index} disablePadding sx={{ px: 2 }}>
            {group.items?.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
