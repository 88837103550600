// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import { ThemeSettings } from "./components/settings";
// react-quill
import 'react-quill/dist/quill.snow.css';

function App() {
  return (
    <ThemeProvider>
      <ThemeSettings>
        <ThemeLocalization>
          <Router />
        </ThemeLocalization>
      </ThemeSettings>
    </ThemeProvider>
  );
}

export default App;
