import { createSlice } from "@reduxjs/toolkit";
import {
  getAboutService,
  createAboutService,
  updateAboutService,
  getAboutByIdService,
  deleteAboutService,
} from "../services/about_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingAbout: false,
  about_us: [],
  aboutInfo: {},
  totalElements: null,
  isLoadingDelete: false,
  isLoadingUpdate: false,
  metaAbout:null,
  aboutId:null

};

const slice = createSlice({
  name: "about_us",
  initialState,
  reducers: {
    setAboutId: (state, action) => {
      state.aboutId = action.payload;
    },
    setAboutInfo: (state, action) => {
      state.aboutInfo = action.payload;
    },
  },
  extraReducers: {
    // ==> GET ABOUT
    [getAboutService.fulfilled]: (state, action) => {
    
      state.isLoading = false;
      state.about_us = action.payload?.data;
      state.metaAbout = action.payload;
    },
    [getAboutService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAboutService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> POST ABOUT
    [createAboutService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createAboutService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createAboutService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> update ABOUT
    [updateAboutService.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateAboutService.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateAboutService.pending]: (state) => {
      state.isLoadingUpdate = true;
    },

      // ==>GET ABOUT BY ID
      [getAboutByIdService.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.aboutInfo=action.payload.data
      },
      [getAboutByIdService.rejected]: (state, action) => {
        state.isLoading = false;
      },
      [getAboutByIdService.pending]: (state) => {
        state.isLoading = true;
      },

    // ==> delete ABOUT
    [deleteAboutService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteAboutService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteAboutService.pending]: (state) => {
      state.isLoadingDelete = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setAboutId ,setAboutInfo} = slice.actions;


