import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET CONTACT US
export const getContactService = createAsyncThunk(
  "contacts/getContactService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.contactURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  POST CONTACT
export const createContactService = createAsyncThunk(
  "contacts/createContactService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createContactURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET CONTACT BY ID 
export const getContactDetailesService = createAsyncThunk(
  "contacts/getContactDetailesService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.contactURL}/${params.id}/show/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
