// routes
import { PATH_DASHBOARD } from "../../../routes/paths";

import SvgColor from "../../../components/svg-color";
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  dashboard: icon("dashboard_ic"),
  students: icon("student_ic"),
  teachers: icon("users_ic"),
  setting: icon("setting_ic"),
  clients: icon("clients"),
  sub: icon("book"),
  profile: icon("profile"),
  forum: icon("forum-svgrepo-com"),
  code: icon("c"),
};

const navConfig = [
  // Admin
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
 

      //about_us
      {
        title: "about_us",
        icon: ICONS.dashboard,
        path: "/dashboard/about_us",
        index: "2",
      },

      //blogs
      {
        title: "blogs",
        icon: ICONS.teachers,
        path: "/dashboard/blogs",
        index: "3",
      },

        //main sliders
        {
          title: "main_sliders",
          icon: ICONS.teachers,
          path: "/dashboard/main_sliders",
          index: "3",
        },

      //clients
      {
        title: "clients",
        icon: ICONS.students,
        path: "/dashboard/clients",
        index: "4",
      },

      //contact_us
      {
        title: "contact_us",
        icon: ICONS.forum,
        path: "/dashboard/contact",
        index: "5",
      },

      //jobs
      {
        title: "jobs",
        icon: ICONS.teachers,
        path: "/dashboard/jobs",
        index: "6",
      },

      //office
      {
        title: "office",
        icon: ICONS.clients,
        path: "/dashboard/office",
        index: "7",
      },

      //people_type
      {
        title: "people_type",
        icon: ICONS.clients,
        path: "/dashboard/people-type",
        index: "7",
      },

      //people
      {
        title: "people",
        icon: ICONS.clients,
        path: "/dashboard/people",
        index: "8",
      },

      //area
      {
        title: "area",
        icon: ICONS.sub,
        path: "/dashboard/area",
        index: "9",
      },

        //topics
        {
          title: "topics",
          icon: ICONS.sub,
          path: "/dashboard/topics",
          index: "9",
        },

      //testimonial
      {
        title: "testimonial",
        icon: ICONS.teachers,
        path: "/dashboard/testimonial",
        index: "10",
      },
      //testimonial_people
      {
        title: "testimonial_people",
        icon: ICONS.code,
        path: "/dashboard/testimonia_people",
        index: "11",
      },

      {
        title: "privacy_policy",
        icon: ICONS.code,
        path: "/dashboard/privacy_policy",
        index: "12",
      },
      {
        title: "cookie_policy",
        icon: ICONS.code,
        path: "/dashboard/cookie_policy",
        index: "13",
      },
      {
        title: "terms_conditions",
        icon: ICONS.code,
        path: "/dashboard/terms_conditions",
        index: "14",
      },
    ],
  },
];

export default navConfig;
