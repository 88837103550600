import { createSlice } from "@reduxjs/toolkit";
import {
  getTestimonialPeopleService,
  createTestimonialPeopleService,
  updateTestiomnalPeopleService,
  deleteTestiomnalPeopleService,
  getTestiomnalByIdService,
  //
  getTestimonialService,
  updateTestiomnalService,
  
} from "../services/testimonial_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingTestimonial: false,
  testimonial_people: [],
  testimonial: [],
  totalElements: null,
  isLoadingDelete: false,
  isLoadingUpdate: false,
  isLoadingCreate: false,
  metaTestimonial:null,
  testimonialInfo:null,
  testimonialId:null,
  testimonialDetailes:null
  
};

const slice = createSlice({
  name: "testimonial",
  initialState,
  reducers: {  setTestimonialId: (state, action) => {
    state.testimonialId = action.payload;
  },
  setTestimonialInfo: (state, action) => {
    state.testimonialInfo = action.payload;
  },},

  extraReducers: {
    // GET =>  GET TESTIONILA PEOPLE
    [getTestimonialPeopleService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.testimonial_people = action.payload?.data;
      state.metaTestimonial = action.payload;
    },
    [getTestimonialPeopleService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTestimonialPeopleService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> CREATE TESTIOMNAL
    [createTestimonialPeopleService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createTestimonialPeopleService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createTestimonialPeopleService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> UPDATE TESTIOMNAL
    [updateTestiomnalPeopleService.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateTestiomnalPeopleService.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateTestiomnalPeopleService.pending]: (state) => {
      state.isLoadingUpdate = true;
    },

    // ==> DELETE TESTIOMNAL
    [deleteTestiomnalPeopleService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteTestiomnalPeopleService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteTestiomnalPeopleService.pending]: (state) => {
      state.isLoadingDelete = true;
    },

    // GET =>  GET TESTIONILA
    [getTestimonialService.fulfilled]: (state, action) => {
      console.log(action.payload?.data)
      state.isLoading = false;
      state.testimonialDetailes = action.payload?.data;
     // state.totalElements = action.payload?.totalElements;
    },
    [getTestimonialService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTestimonialService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> UPDATE PEOPLE
    [updateTestiomnalService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [updateTestiomnalService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [updateTestiomnalService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

      // ==> UPDATE PEOPLE
      [getTestiomnalByIdService.fulfilled]: (state, action) => {
        state.isLoadingCreate = false;
        state.testimonialInfo = action.payload.data;
      },
      [getTestiomnalByIdService.rejected]: (state, action) => {
        state.isLoadingCreate = false;
      },
      [getTestiomnalByIdService.pending]: (state) => {
        state.isLoadingCreate = true;
      },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setTestimonialId, setTestimonialInfo } = slice.actions;

