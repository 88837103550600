import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET BLOGS
export const getBlogsService = createAsyncThunk(
  "blogs/getBlogsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.blogsURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  POST BLOG
export const createBlogService = createAsyncThunk(
  "blogs/createBlogService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createBlogsURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// PUT =>  put BLOG
export const updateBlogService = createAsyncThunk(
  "blogs/updateBlogService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(`${URLS.blogsURL}${params.id}/update/`, params?.data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// PUT =>  GET BLOG BY ID SE
export const getBlogByIdService = createAsyncThunk(
  "blogs/getBlogByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.blogsURL}${id}/show/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// DELETE =>  DELETE BLOG
export const deleteBlogService = createAsyncThunk(
  "blogs/deleteBlogService",
  async (id,thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.blogsURL}${id}/delete/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
