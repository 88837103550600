// enum
export const enumsURL = "/api/v1/system/enums";

// get teachers
export const getTeacherURL = "/api/v1/teacher";

// get student
export const studentURL = "/api/v1/student";

// get teacher by id
export const teacherURL = "/api/v1/teacher";

// get courses for teacher
export const getCoursesTeacherByIdURL = "/api/v1/teacher";

// google meet
export const courseURL = "/api/v1/course";

// parents
export const getParentURL = "/api/v1/parent";

// approve Teacher
export const approveTeacherURL = "/api/v1/users/approveTeacher";

// pending parents
export const getParentsRequestsURL = "/api/v1/users/parentsRequests";

// pending teachers
export const getTeachersRequestsURL = "/api/supervisor/teachersRequests";

// get all cources
export const getCourcesURL = "/api/v1/course";

// upload file
export const uploadFileURL = "api/v1/file/upload";

// upload file
export const createTeacherURL = "/api/v1/teacher/admin/create";

// forum
export const forumURL = "/api/v1/forum";

// subjects
export const subjectsURL = "/api/v1/subjects";

// employee
export const employeeURL = "/api/v1/users/admin";

// add employee
export const addEmployeeURL = "/api/v1/users/admin";

// statistics
export const statisticsURL = "/api/v1/users/statistics";

// codes
export const codesURL = "/api/v1/prepaidCards";

// quizzes
export const quizzesTeachersURL = "/api/v1/quizzes/teacher";

export const quizzesStudentURL = "/api/v1/quizzes/student";

// wallet
export const changeWalletAmountURL = "/api/v1/users/changeWalletAmount";

// supervisor
export const supervisorURL = "/api/supervisor";

// SEARCH
export const searchURL = "/api/v1/searches";

export const questionURL = "/questions";

export const recordedLessonsURL = "/api/v1/course/recordedLessons";

export const assistantURL = "/api/assistant";

export const assistantForTeacherURL = "/api/assistant/teacher";

//
export const competitionURL = "/api/v1/competition";

export const questionOfCompetintionURL = "/api/v1/competition/question/";

////////////////////////////////////////////
// blogs

export const aboutUsURL = "/about-us/";

export const creteAboutUsURL = "/about-us/create/";

export const blogsURL = "/blogs/";

export const createBlogsURL = "/blogs/create/";

export const clientsURL = "/clients/";

export const createClientURL = "/clients/create/";

export const contactURL = "/contact-us/";

export const createContactURL = "/contact-us/create/";

export const homeURL = "/home/main-sliders/";

export const createHomeURL = "/home/main-sliders/create/";

export const jobURL = "/job-appliers/";

export const createJobURL = "/job-appliers/apply/";

export const officeInfoURL = "/office-info/";

export const updateOfficeInfoURL = "/office-info/update/";

export const peopleTypesURL = "/people-types/";

export const createPeopleTypesURL = "/people-types/create/";

export const peopleURL = "/people/";

export const createPeopleURL = "/people/create/";

export const testimonialURL = "/testimonial/";

export const updateTestimonialURL = "/testimonial/update/";

export const testimonialPeopleURL = "/testimonial-people/";

export const createTestimonialPeopleURL = "/testimonial-people/create/";

export const getKeyPracticeAreaURL ="/key-practice-area/"

export const updateKeyPracticeAreaURL ="/key-practice-area/update/"

export const topicURL="/practice-areas/topic/"

export const createTopicURL="/practice-areas/topic/create/"

export const getTermsConditionsURL="/terms-conditions/"

export const updateTermsConditionsURL="/terms-conditions/update/"

export const getPrivacyPolicyURL="/privacy-policy/"

export const updatePrivacyPolicyURL="/privacy-policy/update/"

export const getCookiePloicyURL="/cookie-policy/"

export const updateCookiePloicyURL="/cookie-policy/update/"


